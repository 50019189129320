import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageLink } from '../components/layout/style';
import { colors } from '../constants';

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.postList = [{ year: 2019, list: props.data.articles2019.edges }];
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Mygo explains in articles"
          keywords={[
            `gatsby`,
            `javascript`,
            `react`,
            `mygo explains`,
            `articles`,
          ]}
        />
        <h2
          css={css`
            text-align: center;
            display: block;
          `}
        >
          Articles
        </h2>
        <p>
          This is a new section I intend to populate in the next coming month.
          This will combine personal flavor, with life lessons. Or perhaps the
          most interesting things I've read about recently and my observations
          regarding that.
        </p>
        {this.postList.map(set => {
          return (
            <table
              key={`blog-post-title-${set.year}`}
              css={css`
                border: none;
                border-collapse: collapse;
                border-spacing: 0;
                text-align: left;
              `}
            >
              <tbody>
                {set.list.map(({ node }) => {
                  return (
                    <tr key={node.fields.slug}>
                      <td
                        css={css`
                          padding-right: 2rem;
                          font-style: italic;
                          font-size: 1em;
                          width: 10%;
                          white-space: nowrap;
                        `}
                      >
                        {moment.utc(node.frontmatter.date).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <PageLink
                          css={css`
                            color: ${colors.link.normal};
                          `}
                          to={node.fields.slug}
                        >
                          <span>{node.frontmatter.title}</span>
                        </PageLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </Layout>
    );
  }
}

export default Articles;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    articles2019: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "articles" } }
        frontmatter: { date: { gt: "2019" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date
            title
          }
        }
      }
    }
  }
`;
